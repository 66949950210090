<template>
    <page title="Lembretes">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div v-if="lembretes.length > 0">
            <span class="subtitle-2 font-weight-regular">{{lembretes.length}} resultados encontrados</span>
        </div>

        <template v-for="(lembrete, index) in lembretes">
            <v-card class="my-3 pt-1" color="grey lighten-5">
                <div class="px-2">
                    <v-alert type="error" text border="left" dense class="ma-0 my-2">
                        <v-row no-gutters>
                            <v-col class="">{{lembrete.descricao}}</v-col>
                            <v-col class="shrink caption text-no-wrap">{{lembrete.createdAt | moment("from", "now")}}</v-col>
                        </v-row>
                    </v-alert>
                </div>
                <template v-if="lembrete.fiscalizacoes.length > 0">
                    <v-list-item :to="{ name: 'fiscalizacao_view', params: { id: parseInt(lembrete.fiscalizacoes[0].id) }}" >
                        <v-list-item-avatar>
                            <v-icon class="light-green lighten-3" v-text="getItemIcon(lembrete.fiscalizacoes[0].tipo)"></v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{lembrete.fiscalizacoes[0].financiamento.mutuario}}</v-list-item-title>
                            <v-list-item-subtitle>N. Solicitação:  {{lembrete.fiscalizacoes[0].numeroSolicitacao}} Operação: {{lembrete.fiscalizacoes[0].financiamento.operacao+'-'+lembrete.fiscalizacoes[0].financiamento.operacaoDigito}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-if="lembrete.projetos.length > 0">
                    <v-list-item :to="{ name: 'projeto_view', params: { id: parseInt(lembrete.projetos[0].id) }}" >
                        <v-list-item-content>
                            <v-list-item-title>{{lembrete.projetos[0].cliente}}</v-list-item-title>
<!--                            <v-list-item-subtitle>N. Solicitação:  {{lembrete.fiscalizacoes[0].numeroSolicitacao}} Operação: {{lembrete.fiscalizacoes[0].financiamento.operacao+'-'+lembrete.fiscalizacoes[0].financiamento.operacaoDigito}}</v-list-item-subtitle>-->
                            <div>
                                <v-chip x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                    <span>{{lembrete.projetos[0].tipo.nome}}</span>
                                </v-chip>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-if="lembrete.notasFiscais.length > 0">
                    <v-list-item :to="{ name: 'nota_fiscal_view', params: { id: parseInt(lembrete.notasFiscais[0].id) }}" >
                        <v-list-item-content>
                            <v-list-item-title>{{lembrete.notasFiscais[0].numero}}<span class="font-weight-light"> - {{lembrete.notasFiscais[0].discriminacao}}</span></v-list-item-title>
                            <v-list-item-subtitle class="d-flex">
                                Valor Nota <b class="black--text ml-1">{{lembrete.notasFiscais[0].valorServicos | formatNumber}}</b>
                                <template v-if="lembrete.notasFiscais[0].dataPagamento">
                                    <div class="ml-3">
                                        Valor Pago <b class="green--text">{{lembrete.notasFiscais[0].valorPago | formatNumber}}</b>
                                    </div>
                                    <div class="ml-3">
                                        Descontado <b class="red--text">{{lembrete.notasFiscais[0].descontoPagamento}}%</b>
                                    </div>
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                Emissão: <b>{{lembrete.notasFiscais[0].createdAt | moment("DD MMM YYYY HH:mm")}}</b>
                            </v-list-item-action-text>
                            <v-list-item-action-text>
                                Pagamento: <b>{{lembrete.notasFiscais[0].dataPagamento | moment("DD MMM YYYY")}}</b>
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-card>
        </template>
    </page>
</template>

<script>
    // @ts-ignore
    import page from '../../components/Page';
    import {FiscalizacaoQueries} from "../../queries/FiscalizacaoQueries";
    import gql from "graphql-tag";
    import {getIconByFiscalizacaoTipo} from "../../assets/FiscaizacaoUtil";
    export default {
        name: "Index",
        components: {
            page,
        },
        data(){
            return {
                fiscalizacoes: [],
                lembretes: [],
                loading: true,
                colors: [
                    'green',
                    'secondary',
                    'yellow darken-4',
                    'red lighten-2',
                    'orange darken-1',
                ],
                options: {
                    minSize: 6,
                    maxSize: 20,
                }
            }
        },
        mounted() {
            document.title = "Lembretes - JF Assessoria Rural"
            this.loading = true;
            this.getList();
        },
        methods: {
            getList() {
                this.loading = true;
                this.$apollo.query({
                    query: gql`query {
                        lembretes{
                            descricao
                            createdAt
                            fiscalizacoes{
                                id
                                numeroSolicitacao
                                tipo{
                                    nome
                                }
                                financiamento{
                                    mutuario
                                    operacao
                                    operacaoDigito
                                    cedula
                                }
                            }
                            projetos{
                                id
                                cliente
                                tipo{
                                    nome
                                }
                            }
                            notasFiscais{
                                id
                                numero
                                discriminacao
                                dataPagamento
                                valorPago
                                valorServicos
                                descontoPagamento
                                createdAt
                            }
                            criadoPor{
                                nome
                                sobrenome
                                imagem{
                                    nome
                                }
                            }
                        }
                    }`,
                }).then((result) => {
                    this.lembretes = result.data.lembretes;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                })
                /*this.$apollo.query({
                    query: gql`query {
                        fiscalizacoes:fiscalizacoesComLembrete{
                            id
                            numeroSolicitacao
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                operacao
                                operacaoDigito
                                cedula
                            }
                            lembretes{
                                descricao
                                createdAt
                                criadoPor{
                                    nome
                                    sobrenome
                                    imagem{
                                        nome
                                    }
                                }
                            }
                        }
                    }`,
                }).then((result) => {
                    this.fiscalizacoes = result.data.fiscalizacoes;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                })*/
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
        }
    }
</script>

<style scoped>

</style>